/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

/* @font-face {
  font-family: Raleway;
  src: url(assets/fonts/Raleway-Medium.ttf) format("opentype");
  @import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

} */

@font-face {
  font-family: Samsung;
  src: url(assets/fonts/samsungsharpsans-medium.otf) format("opentype");
}
* {
  font-family: "Raleway";
}

.overlay {
  position: fixed;
  /* background-color: rgba(74, 74, 74, .8); */
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
input {
  /* [type="text"]{ */
  font-family: "Samsung" !important;
  font-weight: lighter;

}
